<template>
    <div class="pt50">
        <div class="box-utility list" v-for="(data,index) in utilityData" :key="`${index}boxUtility`" :class="{ disabled : returnUseState(data) === '기한만료' ||  returnUseState(data) === '사용완료'}" @click="moveDetail(data)">
            <div class="img-utility" :style="`background-image : url('${returnImg(data.UtilityImg[0])}')`">
                <div class="category-utility">{{ data.uc_name }}</div>
                <div class="condition-utility">{{ returnUseState(data) }}</div>
            </div>
            <div class="wrap-content-utility">
                <div class="text-utility-top">Utility</div>
                <div class="title-utility">{{ data.u_name }}</div>
                <div class="wrap-profile-utility">
                    <div class="img-profile-utility" :style="`background-image : url('${returnImg(data.collectionImg[0])}')`"></div>
                    <div class="text-profile-utility">{{ data.c_title }}</div>
                </div>
                <div class="wrap-info-utility">
                    <!-- <div class="text-cnt-utility"> 카운트카운트</div> -->
                    <div class="wrap-mingle-utility">
                        <div class="icon-mingle-utility"></div>
                        <div class="text-mingle-utility">{{ data.u_price }} Mg</div>
                    </div>
                    <div class="text-price-utility">(￦ {{ data.u_price }}0)</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import util from "@/mixins/util";
import dateMixins from "@/mixins/dateMixins";

export default {
    name: "UtilityListDefaultLayout",
    mixins: [dateMixins],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        utilityData: {
            default: () => [],
        }
    },
    data() {
        return {
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        returnImg(data) {
            if (!util.isEmpty(data)) {
                return data.org_url;
            } else {
                return '';
            }
        },
        returnUseState(data) {
            let now = this.returnDateTime(new Date());
            let start = data.u_use_start_date;
            let end = data.u_use_end_date;

            if (data.u_is_use == 1) {
                if (start > now) {
                    return this.$t('use_await') // 사용대기
                } else if (start < now && end > now) {
                    return this.$t('use_possible'); // 사용가능
                } else if (end < now) {
                    return this.$t('use_end_already') //기한만료
                } else {
                    console.log('error')
                }
            } else {
                return this.$t('use_fin') // 사용완료
            }
        },
        moveDetail(data) {
            this.$router.push(`/utility/${data.u_idx}`)
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
